<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="rounded-xl"
      elevation="0"
      color="primary"
      :class="isMobile ? 'py-4' : ''"
      :height="isMobile ? '' : '250'"
      :light="!project.dark"
    >
      <v-expand-transition>
        <div
          v-if="hover && !!project.url && !isMobile"
          class="d-flex transition-fast-in-fast-out justify-center align-center rounded-xl white--text github-card"
          style="height: 100%"
          @click="openNewTab(project.url)"
        >
          <p>
            Visit
            <v-icon color="white" class="mx-1">{{ project.icon }}</v-icon>
            {{ project.channel }}
          </p>
        </div>
        <div
          v-if="hover && !project.url && !isMobile"
          class="d-flex transition-fast-in-fast-out v-card--reveal justify-center align-center rounded-xl white--text"
          style="height: 100%"
        >
          <p>* No project repository due to confidential *</p>
        </div>
      </v-expand-transition>
      <div
        v-if="!hover || isMobile"
        class="d-flex flex-column align-space-between"
      >
        <v-card-title class="white--text text-h5">
          {{ project.name }}
        </v-card-title>
        <v-card-text class="chip--text">
          {{ project.description }}
        </v-card-text>
        <div class="ml-3">
          <v-chip
            class="mx-1 mb-1"
            color="#FFFBCE"
            outlined
            v-for="tag in project.tags"
            :key="tag"
            >{{ tag }}</v-chip
          >
        </div>
      </div>
      <v-card-actions v-if="isMobile">
        <p class="chip--text ml-4 mt-4" v-if="!project.url">
          * No project repo
        </p>
        <v-btn
          class="ml-2 rounded-xl chip primary--text"
          @click="openNewTab(project.url)"
          small
          v-else
          ><v-icon color="primary" class="mr-1">{{ project.icon }}</v-icon>
          Visit {{ project.channel }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    openNewTab(url) {
      window.open(url);
    },
  },
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "md"
      );
    },
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
};
</script>

<style scoped>
a {
  text-decoration: none;
  outline: none;
  color: white !important;
  transition: 0.3s;
}
a:hover {
  color: #ffde82 !important;
}

.github-card {
  background-color: #2d333b;
  cursor: pointer;
}
</style>
