<template>
  <v-btn
    color="primary"
    light
    @click="getMyResume"
    :block="block"
    elevation="0"
    class="text-capitalize white--text rounded-lg"
  >
    <v-icon class="mr-2" small>mdi-google-drive</v-icon>Get my resume
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      resumeUrl:
        "https://drive.google.com/file/d/19rnOZWjy4Y5icT7jbdAGaADzhl-L8nGq/view?usp=sharing",
    };
  },
  methods: {
    getMyResume() {
      window.open(this.resumeUrl);
    },
  },
  computed: {},
  props: {
    block: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
